<template>
  <div class="campaign-setup-suppression">
    <section>
      <template v-if="loading">
        <campaign-suppression-skeleton />
      </template>
      <template v-else-if="errorMessage">
        <p class="campaign-setup-suppression__error">
          {{ errorMessage }}
        </p>
      </template>
      <template v-else>
        <p>This campaign has email supression enabled.</p><br>
        <p>If you are planning on running email for this campaign, please download the suppression file and make sure you clean your own list(s) against this file before each email campaign.</p>
        <el-button-group>
          <el-button class="next-button campaign-setup-suppression__button" icon="el-icon-view" @click="handleView">
            View Suppression
          </el-button>
          <el-button class="next-button campaign-setup-suppression__button" icon="el-icon-link" @click="handleCopyLink">
            Opt-Out URL
          </el-button>
        </el-button-group>
      </template>
    </section>
  </div>
</template>

<script>
import CampaignSuppressionSkeleton from '@/components/skeletons/campaigns/CampaignSuppressionSkeleton';
import CopyToClipboardService from '@/services/copy-to-clipboard-service';

export default {
  name: 'CampaignSetupSuppression',
  components: {
    CampaignSuppressionSkeleton
  },
  data() {
    return {
      loading: false,
      optOutUrl: '',
      mailerUrl: '',
      errorMessage: ''
    };
  },
  computed: {
    orgId() {
      return this.$store.getters.organization.id;
    },
    campaign() {
      return this.$store.getters.campaign(this.$route.params.id);
    },
    campaignId() {
      return this.$route.params.id;
    }
  },
  watch: {
    campaignId: {
      immediate: true,
      handler(newVal, oldVal) {
        this.getSuppressions();
      }
    }
  },
  methods: {
    getSuppressions() {
      this.loading = true;
      this.$store
        .dispatch('getSuppressionData', {
          orgId: this.orgId,
          campaignId: this.campaignId
        })
        .then(response => {
          this.loading = false;
          this.optOutUrl = response.optout_url;
          this.mailerUrl = response.mailer_url;
        })
        .catch(e => {
          this.loading = false;
          this.errorMessage =
          'This campaign does not have email suppression enabled.';
        });
    },
    handleView() {
      window.open(this.mailerUrl, '_blank').focus();
    },
    handleCopyLink(template) {
      CopyToClipboardService.copy(this.optOutUrl)
        .then(val => {
          this.$message({
            showClose: true,
            message: 'Copied to clipboard'
          });
        })
        .catch(e => {
          this.$message({
            message: 'There was an error copying the Opt-Out Link.',
            type: 'error'
          });
        });
    }
  }
};
</script>

<style lang="scss">
.campaign-setup-suppression {
  @include stage-layout;

  @apply tw-mt-0;

  section {
    padding-bottom: $--clb-layout-4;
  }

  p {
    max-width: 700px;
  }

  &__error {
    color: $--clb-color-info;
  }

  .el-button-group {
    width: 100%;
    transition: none;

    .campaign-setup-suppression__button {
      display: inline-block;
      margin: $--clb-layout-2 0 0 0;
      height: 40px;
      width: 50% !important;
      font-size: $--clb-font-size-xs;
      transition: $--all-transition, width 0ms;

      i {
        display: none;
      }

      &:first-child {
        border-right: 1px solid rgba(255, 255, 255, 0.75);
        margin-right: 0 !important;
      }

      &:active,
      &:hover,
      &:focus {
        transform: translateY(0) !important;
      }
    }
  }

  @media (min-width: $--sm) {
    .el-button-group {
      width: auto !important;

      .campaign-setup-suppression__button {
        width: auto !important;

        i {
          display: inline;
        }
      }
    }
  }

  @media (min-width: $--md) {
    .el-button-group {
      .campaign-setup-suppression__button {
        font-size: $--clb-font-size-sm;
      }
    }
  }
}
</style>
