<template>
  <div class="campaign-suppression-skeleton">
    <skeleton-box height="16px" width="320px" />
    <br>
    <skeleton-box height="16px" width="90%" />
    <skeleton-box height="16px" width="80%" />
  </div>
</template>

<script>
import SkeletonBox from '@/components/skeletons/SkeletonBox';

export default {
  name: 'CampaignSupressionSkeleton',
  components: { SkeletonBox }
};
</script>

<style lang="scss" scoped>
.campaign-suppression-skeleton {
  width: 100%;
  max-width: 700px;

  svg {
    margin: $--clb-space-1 0;
  }
}
</style>
